import React from 'react'
import { Link } from "react-router-dom";
const Login = () => {
    return (
        <div>
            <section className="page-section pb-100 pb-sm-60 bg-gray-light-1 bg-light-alpha-80 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/full-width-images/page-title-bg-5.jpg'})` }}>
                <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-gradient-white"></div>
                <div className="container position-relative pt-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">

                                <h2 className="section-caption-border mb-30 mb-xs-20 wow fadeInUp" data-wow-duration="1.2s">
                                    Account
                                </h2>

                                <h1 className="hs-title-1 mb-0">
                                    <span className="wow charsAnimIn" data-splitting="chars">Please login.</span>
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section pt-0">
                <div className="container wow fadeInUp">
                    <div className="align-center mb-40 mb-sm-30">
                        <ul className="nav nav-tabs tpl-minimal-tabs animate" id="myTab-account" role="tablist">

                            <li className="nav-item" role="presentation">
                                <Link href="#account-login" className="nav-link active" id="account-login-tab" data-bs-toggle="tab" role="tab" aria-controls="account-login" aria-selected="true">Login</Link>
                            </li>

                        </ul>
                    </div>
                    <div className="tab-content tpl-minimal-tabs-cont section-text" id="myTabContent-1">

                        <div className="tab-pane fade show active" id="account-login" role="tabpanel" aria-labelledby="account-login-tab">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">

                                    <form className="form contact-form">

                                        <div className="mb-30">
                                            <div className="form-group">
                                                <label for="username">Username</label>
                                                <input type="text" name="username" id="username" className="input-lg round form-control" placeholder="Enter username" pattern=".{3,100}" required aria-required="true" />
                                            </div>
                                            <div className="form-group">
                                                <label for="password">Password</label>
                                                <input type="password" name="password" id="password" className="input-lg round form-control" placeholder="Enter password" pattern=".{5,100}" required aria-required="true" />
                                            </div>

                                        </div>

                                        <div className="row mb-30">

                                            <div className="col-6">
                                                <div className="form-tip pt-10">
                                                    <Link href="#">Forgot Password?</Link>
                                                </div>

                                            </div>

                                            <div className="col-6">
                                                <div className="text-end">
                                                    <button className="submit_btn btn btn-mod btn-large btn-round btn-hover-anim" id="login-btn"><span>Login</span></button>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="form-tip bg-gray-light-1 round p-3">
                                            <i className="icon-info size-16"></i>
                                            All the fields are required. By sending the form you agree to the <Link href="#">Terms & Conditions</Link> and <Link href="#">Privacy Policy</Link>.
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Login