import React from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../constants';
import SampleCsv from './SampleCsv';
import { MultiSelect } from 'react-multi-select-component';


const GetAQuote = () => {
    const [selectedTopTrim, setSelectedTopTrim] = useState([]);
    
    const [selectedBottomTrim, setSelectedBottomTrim] = useState([]);
    const [options, setOptions] = useState();
    const [error, setError] = useState(null);

    const[filteredCategory, setFilteredCategory] = useState([])
    const[filteredProduct, setFilteredProduct] = useState([])
    const[filteredSilhouette, setFilteredSilhouette] = useState([])
    const [selectedProductPrice, setSelectedProductPrice] = useState(0);
    const [selectedProductType, setselectedProductType] = useState('');

    const [selectedSilhouttePrice, setSelectedSilhouttePrice] = useState(0);
    const [selectedSilhoutteType, setSelectedSilhoutteType] = useState('');
    const [selectedSilhoutteUrl, setSelectedSilhoutteUrl] = useState('/images/Quote-product.jpg');


    const [selectedFabricPrice, setSelectedFabricPrice] = useState(0);
    const [selectedFabricType, setSelectedFabricType] = useState('');

    const [selectedtopWearClosurePrice, setSelectedtopWearClosurePrice] = useState(0);
    const [selectedtopWearClosureType, setselectedtopWearClosureType] = useState('');

    const [selectedtopWearTrimsPrice, setSelectedtopWearTrimsPrice] = useState(0);
    const [selectedtopWearTrimsType, setselectedtopWearTrimsType] = useState([]);
    const [topWearTrim, setTopWearTrim] = useState([{label:'',value:''}]);


    const [selectedbottomOpeningPrice, setSelectedbottomOpeningPrice] = useState(0);
    const [selectedbottomOpeningType, setselectedbottomOpeningType] = useState('');

    const [selectedbottomWearTrimsPrice, setSelectedbottomWearTrimsPrice] = useState(0);
    const [selectedbottomWearTrimsType, setselectedbottomWearTrimsType] = useState([]);
    const [bottomWearTrim, setBottomWearTrim] = useState([{label:'',value:''}]);

    const [selectedMOQBucketPrice, setSelectedMOQBucketPrice] = useState(0);
    const [selectedMOQBucketType, setselectedMOQBucketType] = useState('');

    const [totalPrice, setTotalPrice] = useState(0);
    const [divClass, setDivClass] = useState('cookie-banner hide');
    const [confirmtDivClass, seConfirmtDivClass] = useState('cookie-banner hide-now');

    //for top wear trims
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [totalTrimPrice, setTotalTrimPrice] = useState(0);
    const [laceValue, setLaceValue] = useState(0)
    const [buttonValue, setButtonValue] = useState(0)
    const [snapValue, setSnapValue] = useState(0)
    const [rivetsValue, setRivetsValue] = useState(0)
    const [scallopedValue, setScallopedValue] = useState(0)
    const [tucksValue, setTucksValue] = useState(0)

    //for bottom wear trims
    const [selectedOptionsBottom, setSelectedOptionsBottom] = useState([]);
    const [totalTrimPriceBottom, setTotalTrimPriceBottom] = useState(0);
    const [bottomlaceValue, setBottomLaceValue] = useState(0)
    const [bottombuttonValue, setBottomButtonValue] = useState(0)
    const [bottomsnapValue, setBottomSnapValue] = useState(0)
    const [bottomrivetsValue, setBottomRivetsValue] = useState(0)
    const [bottomscallopedValue, setBottomScallopedValue] = useState(0)
    const [bottomtucksValue, setBottomTucksValue] = useState(0)
    const [formData, setFormData] = useState({
        segment: "",
        category: "",
        productType: selectedProductType,
        silhouetteType: selectedSilhoutteType,
        fabric:selectedFabricType,
        top_length: "",
        top_chest: "",
        top_waist: "",
        top_hip: "",
        top_neck: "",
        top_sleeves: "",
        top_cuff: "",
        top_front_placket: "",
        top_wear_closure: "",
        top_pockets: "",
        top_wear_trims: selectedtopWearTrimsType,
        bottom_waist: "",
        bottom_in_seam: "",
        bottom_out_seam: "",
        bottom_opening: selectedbottomOpeningType,
        waistBand: "",
        waistType: "",
        bottom_pockets: "",
        bottomWearTrims: selectedbottomWearTrimsType,
        MOQBucket: selectedMOQBucketType,
        color_ways: "",
        customer_name: "",
        customer_email: "",
        customer_phoneNo: "",
        customer_brandName: "",
        totalPrice:totalPrice
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'segment') {
            const items = options?.category?.filter((item) => item.type.includes(value));
            setFilteredCategory(items)
            setFilteredProduct([])
            setFilteredSilhouette([])
        }
        if (name === 'category') {
        const items = options?.productType?.filter((item) => item.type.includes(value));
        setFilteredProduct(items)
        console.log(items,'oooo')
        setFilteredSilhouette([])
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(API_URL+"submit-enquiry", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                throw new Error("Failed to submit form");
            }
    
            const result = await response.json();
            console.log("Form submitted successfully:", result);
            setDivClass('cookie-banner hide');
            seConfirmtDivClass('cookie-banner show-now');
            setTimeout(() => {
                window.location.assign('/');
            }, 2000);
            
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    useEffect(() => {
        axios.get(API_URL+'getprice')
          .then(response => {
            setOptions(response.data);
           
            const updatedTopWearTrim = response.data.topWearTrims.map((option) => {
                return { label: option.name, value: option.name };
              });
              setTopWearTrim(updatedTopWearTrim)
              const updatedBottomWearTrim = response.data.bottomWearTrims.map((option) => {
                return { label: option.name, value: option.name };
              });
           
            setBottomWearTrim(updatedBottomWearTrim)
            // console.log(response.data,'oooooooo')
          })
          .catch(error => {
            setError(error.message);
          });
      }, []);

    const handleProductTypePrice = (event) => {
        const items = options?.silhouetteType?.filter((item) => filteredProduct[0]?.type.includes(item.type) );
        
        setFilteredSilhouette(items)
        const selectedName = event.target.value;
        const selectedproductOption = options.productType.find(option => option.name === selectedName);
        setSelectedProductPrice(selectedproductOption ? selectedproductOption.price : null);
        setselectedProductType(selectedproductOption ? selectedproductOption.name : null);
        setFormData((prevData) => ({
            ...prevData,
            'productType': selectedName,
        }));
    };
    const handleSilhoutteTypePrice = (event) => {
        const selectedName = event.target.value;
        const selectedSilhoutteOption = options.silhouetteType.find(option => option.name === selectedName);
        setSelectedSilhouttePrice(selectedSilhoutteOption ? selectedSilhoutteOption.price : null);
        setSelectedSilhoutteType(selectedSilhoutteOption ? selectedSilhoutteOption.name : null);
        setSelectedSilhoutteUrl(selectedSilhoutteOption ? selectedSilhoutteOption.url : null);

        setFormData((prevData) => ({
            ...prevData,
            'silhouetteType': selectedName,
        })); 
    };
      const handleFabricPrice = (event) => {
        const selectedName = event.target.value;
        const selectedFabricOption = options.fabric.find(option => option.name === selectedName);
        setSelectedFabricPrice(selectedFabricOption ? selectedFabricOption.price : null);
        setSelectedFabricType(selectedFabricOption ? selectedFabricOption.name : null);
        setFormData((prevData) => ({
            ...prevData,
            'fabric': selectedName,
        })); 
      };
      
    //   const handletopWearClosurePrice = (event) => {
    //     const selectedName = event.target.value;
    //     const selectedtopWearClosureOption = options.topWearClosure.find(option => option.name === selectedName);
    //     setSelectedtopWearClosurePrice(selectedtopWearClosureOption ? selectedtopWearClosureOption.price : null);
    //     setselectedtopWearClosureType(selectedtopWearClosureOption ? selectedtopWearClosureOption.name : null);
    //   };

      //Calculated Top Wear Trim Multi Select Price
      const handletopWearTrimsPrice = (selectedOptions) => {
        setSelectedTopTrim(selectedOptions);
        // const selectedName = event.target.value;
        const trimsOptions = selectedOptions.map(option => option.value);

        // Set the selected options
        setSelectedOptions(trimsOptions);
        const optionsToExclude = ['Lace','Buttons','Snaps','Rivets','Scalloped Edges','Tucks'];
         // Find prices for selected values
        const selectedPrices = options.topWearTrims
        .filter(trim => trimsOptions.includes(trim.name) && !optionsToExclude.includes(trim.name))
        .map(trim => trim.price);
        const total = selectedPrices.reduce((acc, price) => acc + price, 0);
        setTotalTrimPrice(total);
        // console.log(totalTrimPrice,'pricedddddd')
        // Find names for selected values
        const selectedNames = options.topWearTrims
            .filter(trim => trimsOptions.includes(trim.name))
            .map(trim => trim.name);

        // Update state with prices and names
        setSelectedtopWearTrimsPrice(selectedPrices);
        setselectedtopWearTrimsType(selectedNames);
       
        setFormData((prevData) => ({
            ...prevData,
            'top_wear_trims': selectedNames,
        })); 
    }
    // const handleMultiSelectChangeTopTrim = (selectedOptions) => {
    //    // Update the selected state
    //     // handletopWearTrimsPrice(selectedOptions); // Call the function to handle prices and form data
    //   };
      const handlebottomWearTrimsPrice = (selectedOptions) => {
        setSelectedBottomTrim(selectedOptions);
        // const selectedName = event.target.value;
        const trimsOptions = selectedOptions.map(option => option.value);

        // Set the selected options
        setSelectedOptionsBottom(trimsOptions);
       
       
        const optionsToExclude = ['Lace','Buttons','Snaps','Rivets','Scalloped Edges','Tucks'];
         // Find prices for selected values
        const selectedPrices = options.bottomWearTrims
        .filter(trim => trimsOptions.includes(trim.name) && !optionsToExclude.includes(trim.name))
        .map(trim => trim.price);
        const total = selectedPrices.reduce((acc, price) => acc + price, 0);
        setTotalTrimPriceBottom(total);
        console.log(totalTrimPriceBottom,'pricedddddd')
        // Find names for selected values
        const selectedNames = options.bottomWearTrims
            .filter(trim => trimsOptions.includes(trim.name))
            .map(trim => trim.name);

        // Update state with prices and names
        // setSelectedtopWearTrimsPrice(selectedPrices);
        setselectedbottomWearTrimsType(selectedNames);
        setFormData((prevData) => ({
            ...prevData,
            'bottomWearTrims': selectedNames,
        })); 
    }
      const handleMOQBucketPrice = (event) => {
        const selectedName = event.target.value;
        const selectedMOQBucketOption = options.MOQBucket.find(option => option.name === selectedName);
        setSelectedMOQBucketPrice(selectedMOQBucketOption ? selectedMOQBucketOption.price : null);
        setselectedMOQBucketType(selectedMOQBucketOption ? selectedMOQBucketOption.name : null);
        setFormData((prevData) => ({
            ...prevData,
            'MOQBucket': selectedName,
        }));
      }

      const getTotalPrice = () => {
        const total = Number(selectedProductPrice) +
            Number(selectedSilhouttePrice) +
            Number(selectedFabricPrice) +
            // Number(selectedtopWearClosurePrice) +
            // Number(selectedtopWearTrimsPrice) +
            Number(selectedbottomOpeningPrice) +
            // Number(selectedbottomWearTrimsPrice) +
            Number(totalTrimPrice) +
            Number(totalTrimPriceBottom) +
            Number(laceValue) + 
            Number(buttonValue) +
            Number(snapValue) +
            Number(rivetsValue) +
            Number(scallopedValue) +
            Number(tucksValue) +
            Number(bottomlaceValue) + 
            Number(bottombuttonValue) +
            Number(bottomsnapValue) +
            Number(bottomrivetsValue) +
            Number(bottomscallopedValue) +
            Number(bottomtucksValue) +
            100
            // console.log(total,'uuuuuuu')
        const moqTotal = total * 0.70;
        const finalPrice = total + moqTotal;
        setTotalPrice(finalPrice);
        setDivClass('cookie-banner show');
        setFormData((prevData) => ({
            ...prevData,
            'totalPrice': finalPrice,
        }));
      }
     

   
    return (
        <div>
            <div id="cookie-banner" className={divClass} style={{display: 'none', position: 'fixed',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',maxWidth: '500px',padding: '10px',zIndex: 999}}>
                <div className="cookie-banner-inner text-center">
                    <div className="icons-example-box-icon">
                        <i className="mi-check" aria-hidden="true"></i>
                    </div>
                    <h2 className="h4 mb-10">Thank You</h2>
                    <p className="cookie-banner-text">
                        Your Quote has been submitted for the final price of<br/>
                        <span>₹{totalPrice}/Piece</span><br></br>
                        Give us some of your details so that we can connect with you for further enquiries. 
                    </p>
                    <form id="form" className="form">
                        <div className="row">
                        <div className="col-md-6 mb-20 mb-md-10 col-6">
                            <input
                            type="text"
                            name="customer_name"
                            id="customer_name"
                            className="input-lg round form-control"
                            placeholder="Name"
                            maxLength="100"
                            value={formData.customer_name}
                            onChange={handleChange}
                            required
                            />
                        </div>
                        <div className="col-md-6 mb-20 mb-md-10 col-6">
                            <input
                            type="email"
                            name="customer_email"
                            id="customer_email"
                            className="input-lg round form-control"
                            placeholder="Email"
                            maxLength="100"
                            value={formData.customer_email}
                            onChange={handleChange}
                            required
                            />
                        </div>
                        <div className="col-md-6 mb-20 mb-md-10 col-6">
                            <input
                            type="text"
                            name="customer_phoneNo"
                            id="customer_phone"
                            className="input-lg round form-control"
                            placeholder="Phone"
                            maxLength="100"
                            value={formData.customer_phoneNo}
                            onChange={handleChange}
                            required
                            />
                        </div>
                        <div className="col-md-6 mb-20 mb-md-10 col-6">
                            <input
                            type="text"
                            name="customer_brandName"
                            id="customer_brandName"
                            className="input-lg round form-control"
                            placeholder="Domain (optional)"
                            maxLength="100"
                            value={formData.customer_brandName}
                            onChange={handleChange}
                            />
                        </div>
                        <div>
                            <a
                            href="#"
                            className="col-md-12 btn btn-mod btn-large btn-round btn-hover-anim"
                            onClick={handleSubmit}>
                            <span>Submit</span>
                            </a>
                        </div>
                        </div>
                    </form>
                </div>
            </div>

            <div id="cookie-banner" className={confirmtDivClass} style={{display: 'none', position: 'fixed',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',maxWidth: '500px',padding: '10px',zIndex: 999}}>
                <div className="cookie-banner-inner text-center">
                    <div className="icons-example-box-icon">
                        <i className="mi-check" aria-hidden="true"></i>
                    </div>
                    <h2 className="h4 mb-10">Thank You</h2>
                    <p className="cookie-banner-text">
                        Your Details have been submitted successfully.<br />
                        We will reach to you soon.
                    </p>
                </div>
            </div>
           
            <section className="page-section bg-gray-light-1 bg-light-alpha-90 parallax-5"
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/full-width-images/section-bg-1.jpg'})` }} id="home">
                <div className="container position-relative pt-30 pt-sm-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">Get A Quote</span>
                                </h1>
                                <div className="row">
                                    <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                                        <p className="section-descr mb-0 wow fadeIn" data-wow-delay="0.2s"
                                            data-wow-duration="1.2s">
                                            Share your requirements with us for your best product.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="page-section">
                <div className="container position-relative">
                    <SampleCsv />
                    <hr className="mt-30 mb-40" />
                    <div className="row">
                        <div className="col-md-8">
                            <form method="post" action="#" id="form" className="form">
                                <div className="row">
                                    <div className="col-md-6 mb-20 mb-md-10 col-6">
                                        <select className="input-lg round form-control" name="segment" value={formData.segment} onChange={handleChange}>
                                            <option>Segment</option>
                                            {options?.segment?.map(option => (
                                            <option value={option.name}>{option.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-20 mb-md-10 col-6">
                                        <select className="input-lg round form-control" name="category" value={formData.category} onChange={handleChange}>
                                            <option>Category Type</option>
                                            {filteredCategory?.map(option => (
                                            <option value={option.name}>{option.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-20 mb-md-10 col-6">
                                        <select className="input-lg round form-control" name="product_type" onChange={handleProductTypePrice}>
                                        <option>Product Type</option>
                                        {filteredProduct?.map(option => (
                                        <option value={option.name}>{option.name}</option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-20 mb-md-10 col-6">
                                        <select className="input-lg round form-control" name="silhouette_type" onChange={handleSilhoutteTypePrice}>
                                            <option>Silhouette Type</option>
                                            {filteredSilhouette?.map(option => (
                                            <option value={option.name}>{option.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-20 mb-md-10 col-6">
                                            <select className="input-lg round form-control" name="silhouette_type" onChange={handleFabricPrice}>
                                                <option>Fabric</option>
                                                {options?.fabric?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                </div>
                                <div className="row preview-mobile-img">
                                    <div className="col-md-4">
                                        <img className="services-image" src={selectedSilhoutteUrl} alt="Image Description" />
                                    </div>
                                </div>
                                <hr className="mt-20 mb-20" />
                                <div className="row">
                                    <div className="col-md-6 col-6">
                                        <label htmlFor="exampleInputFile" className="mb-20">Top Wear</label>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_length" id="top_length" className="input-lg round form-control" placeholder="Length" maxLength="100" value={formData.top_length} onChange={handleChange} />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_chest" id="top_chest" className="input-lg round form-control" placeholder="Chest" maxLength="100" value={formData.top_chest} onChange={handleChange} />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_waist" id="top_waist" className="input-lg round form-control" placeholder="Waist" maxLength="100" value={formData.top_waist} onChange={handleChange} />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_hip" id="top_hip" className="input-lg round form-control" placeholder="Hip" maxLength="100" value={formData.top_hip} onChange={handleChange} />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_neck" id="top_neck" className="input-lg round form-control" placeholder="Neck" maxLength="100" value={formData.top_neck} onChange={handleChange} />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_sleeves" id="top_sleeves" className="input-lg round form-control" placeholder="Sleeves" maxLength="100" value={formData.top_sleeves} onChange={handleChange} />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_cuff" id="top_cuff" className="input-lg round form-control" placeholder="Cuff" maxLength="100" value={formData.top_cuff} onChange={handleChange} />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_front_placket" id="top_front_placket" className="input-lg round form-control" placeholder="Front Placket" maxLength="100" value={formData.top_front_placket} onChange={handleChange} />
                                        </div>
                                        {/* <div className="mb-20 mb-md-10">
                                            <input type="text" name="top_buttons" id="top_buttons" className="input-lg round form-control" placeholder="Buttons" maxLength="100" value={formData.top_buttons} onChange={handleChange} />
                                        </div> */}
                                        <div className="mb-20 mb-md-10">
                                            <select className="input-lg round form-control"  value={formData.top_closure} // Set value from formData
          onChange={handleChange}  name="top_wear_closure">
                                                <option>Closure</option>
                                                {options?.topWearClosure?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-20 mb-md-10">
                                            <input type="number" name="top_pockets" id="top_pockets" className="input-lg round form-control" placeholder="Pockets" maxLength="100" value={formData.top_pockets} onChange={handleChange} />
                                        </div>
                                        {/* <div className="mb-20 mb-md-10">
                                            <select className="input-lg round form-control" name="top_trims" onChange={handletopWearTrimsPrice}>
                                                <option>Trims</option>
                                                {options?.topWearTrims?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div> */}
                                        <div className="mb-20 mb-md-10">
                                        <MultiSelect
                                            options={topWearTrim}
                                            value={selectedTopTrim}
                                            onChange={handletopWearTrimsPrice}
                                            labelledBy="Select"
                                            overrideStrings={{
                                                selectSomeItems: "Trims", // Custom placeholder text

                                              }}
                                            
                                        />
                                        {/* <select multiple className="input-lg round form-control" name="top_trims" onChange={handletopWearTrimsPrice} style={{height: 100+'px'}}>
                                        <option>Trims</option>
                                                {options?.topWearTrims?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                        </select> */}
                                    </div>
                                    <div>
        {/* <h3>Selected Options:</h3>
        <ul>
          {selectedOptions.map((option, index) => (
            <li key={index}>{option}</li>
          ))}
        </ul> */}
      </div>
                                        { selectedOptions.includes('Lace') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_lace" id="lace" className="input-lg round form-control" placeholder="Lace(in meters)" maxLength="100"  onChange={(e)=> setLaceValue(e.target.value * 12)} />
                                        </div>
                                    }
                                     { selectedOptions.includes('Buttons') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_buttons" id="buttons" className="input-lg round form-control" placeholder="Buttons" maxLength="100"  onChange={(e)=> setButtonValue(e.target.value * 5)} />
                                        </div>
                                    }
                                     { selectedOptions.includes('Snaps') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_snaps" id="snaps" className="input-lg round form-control" placeholder="Snaps" maxLength="100" onChange={(e)=> setSnapValue(e.target.value * 0.5)} />
                                        </div>
                                    }
                                     { selectedOptions.includes('Rivets') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_rivets" id="rivets" className="input-lg round form-control" placeholder="Rivets" maxLength="100" onChange={(e)=> setRivetsValue(e.target.value * 4)} />
                                        </div>
                                    }
                                     { selectedOptions.includes('Scalloped Edges') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_scallopedEdges" id="scalloped-edges" className="input-lg round form-control" placeholder="Scalloped Edges" maxLength="100" onChange={(e)=> setScallopedValue(e.target.value * 0.5)} />
                                        </div>
                                    }
                                     { selectedOptions.includes('Tucks') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="top_tucks" id="tucks" className="input-lg round form-control" placeholder="Tucks" maxLength="100" onChange={(e)=> setTucksValue(e.target.value * 0.5)} />
                                        </div>
                                    }
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <label htmlFor="exampleInputFile" className="mb-20">Bottom Wear</label>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_waist" id="bottom_waist" className="input-lg round form-control" placeholder="Waist" maxLength="100" value={formData.bottom_waist} onChange={handleChange}  />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_in_seam" id="bottom_in_seam" className="input-lg round form-control" placeholder="In Seam" maxLength="100" value={formData.bottom_in_seam} onChange={handleChange} />
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_out_seam" id="bottom_out_seam" className="input-lg round form-control" placeholder="Out Seam" maxLength="100" value={formData.bottom_out_seam} onChange={handleChange} />
                                        </div>
                                        <div className="mb-20 mb-md-10">
                                            <select className="input-lg round form-control" name="bottom_opening" onChange={handleChange}>
                                                <option>Bottom Opening</option>
                                                {options?.bottomOpening?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-20 mb-md-10">
                                            <select className="input-lg round form-control" name="waistBand" onChange={handleChange}>
                                                <option>Waist Band</option>
                                                {options?.waistBand?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-20 mb-md-10">
                                            <select className="input-lg round form-control" name="waistType" onChange={handleChange}>
                                                <option>Waist Type</option>
                                                {options?.waistType?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className=" mb-20 mb-md-10">
                                            <input type="number" name="bottom_pockets" id="bottom_pockets" className="input-lg round form-control" onChange={handleChange} placeholder="Pockets" maxLength="100" />
                                        </div>
                                        {/* <div className="mb-20 mb-md-10">
                                            <select className="input-lg round form-control" name="bottomWearTrims" onChange={handlebottomWearTrimsPrice}>
                                                <option>Trims</option>
                                                {options?.bottomWearTrims?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div> */}
                                         
                                        <div className="mb-20 mb-md-10">
                                        <MultiSelect
                                            options={bottomWearTrim}
                                            value={selectedBottomTrim}
                                            onChange={handlebottomWearTrimsPrice}
                                            labelledBy="Select"
                                            overrideStrings={{
                                                selectSomeItems: "Trims", // Custom placeholder text

                                              }}
                                            
                                        />
                                        {/* <select multiple className="input-lg round form-control" name="top_trims" onChange={handlebottomWearTrimsPrice} style={{height: 100+'px'}}>
                                                {options?.bottomWearTrims?.map(option => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                        </select> */}
                                    </div>
                                    <div>
        {/* <h3>Selected Options:</h3>
        <ul>
          {selectedOptionsBottom.map((option, index) => (
            <li key={index}>{option}</li>
          ))}
        </ul> */}
      </div>
                                        { selectedOptionsBottom.includes('Lace') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_lace" id="bottom_lace" className="input-lg round form-control" placeholder="Lace(in meters)" maxLength="100"  onChange={(e)=> setBottomLaceValue(e.target.value * 12)} />
                                        </div>
                                    }
                                     { selectedOptionsBottom.includes('Buttons') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_buttons" id="bottom_buttons" className="input-lg round form-control" placeholder="Buttons" maxLength="100"  onChange={(e)=> setBottomButtonValue(e.target.value * 5)} />
                                        </div>
                                    }
                                     { selectedOptionsBottom.includes('Snaps') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_snaps" id="bottom_snaps" className="input-lg round form-control" placeholder="Snaps" maxLength="100" onChange={(e)=> setBottomSnapValue(e.target.value * 0.5)} />
                                        </div>
                                    }
                                     { selectedOptionsBottom.includes('Rivets') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_rivets" id="bottom_rivets" className="input-lg round form-control" placeholder="Rivets" maxLength="100" onChange={(e)=> setBottomRivetsValue(e.target.value * 4)} />
                                        </div>
                                    }
                                     { selectedOptionsBottom.includes('Scalloped Edges') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_scallopedEdges" id="bottom_scalloped-edges" className="input-lg round form-control" placeholder="Scalloped Edges" maxLength="100" onChange={(e)=> setBottomScallopedValue(e.target.value * 0.5)} />
                                        </div>
                                    }
                                     { selectedOptionsBottom.includes('Tucks') &&
                                        <div className=" mb-20 mb-md-10">
                                            <input type="text" name="bottom_tucks" id="bottom_tucks" className="input-lg round form-control" placeholder="Tucks" maxLength="100"  onChange={(e)=> setBottomTucksValue(e.target.value * 0.5)} />
                                        </div>
                                    }
            
                                    </div>
                                </div>
                                <hr className="mt-20 mb-20" />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-md-10">
                                            <label htmlFor="exampleInputFile" className="mb-20">MOQ Bucket</label>
                                            <div className="mb-20 mb-md-10">
                                                <select className="input-lg round form-control" name="MOQBucket" onChange={handleMOQBucketPrice}>
                                                    {options?.MOQBucket?.map(option => (
                                                    <option value={option.name}>{option.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className=" mb-20 mb-md-10">
                                            <label htmlFor="exampleInputFile" className="mb-20">Color Ways / Per Bucket</label>
                                            <input type="text" name="color_ways" onChange={handleChange} id="color_ways" className="input-lg round form-control" placeholder="Color Ways" maxLength="100" />
                                        </div>
                                    </div>
                                </div>
                                <Link to="#" className="col-md-12 btn btn-mod btn-large btn-round btn-hover-anim" onClick={getTotalPrice}><span>Submit</span></Link>
                            </form>
                        </div>
                        <div className="col-md-4 preview-desktop-img">
                            <img className="services-image" src={selectedSilhoutteUrl} alt="Image Description" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
    )
    
}

export default GetAQuote