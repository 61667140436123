import React from 'react'
import Banner from './Banner'
import About from './About'
import Services from './Services'
import LogoType from './LogoType'
import Achievement from './Achievement'
import Testimonials from './Testimonials'
import AboutSection from './AboutSection'
import AboutCatalog from './AboutCatalog'

const Home = () => {
    return (
        <main id="main">
            <Banner />
            <LogoType />
            <Achievement />
            <Services />
            <About />
            <hr className="mt-0 mb-0" />
            <Testimonials />
            <AboutSection />
            <AboutCatalog />
        </main>
    )
}

export default Home