import React from 'react'
import { Link } from "react-router-dom";

const About = () => {
    return (
        <section className="page-section" id="about">
            <div className="container position-relative">
                <div className="row mb-60 mb-xs-30">
                    <div className="col-md-6">
                        <h2 className="homepage-section-heading section-caption mb-xs-10">Our Story</h2>
                        <h3 className="section-title mb-0"><span className="wow charsAnimIn" data-splitting="chars">Anyone can create if they look inside.</span></h3>
                    </div>
                    <div className="col-md-5 offset-md-1 relative text-start text-md-end pt-40 pt-sm-20 local-scroll">
                        <div className="decoration-2 d-none d-md-block" data-rellax-y data-rellax-speed="0.7" data-rellax-percentage="-0.2">
                            <img src="images/decoration-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div className="row wow fadeInUp" data-wow-delay="0.5s">
                    <div className="col-lg-12 col-xl-12 pt-20" style={{ background: "#545454"}}>
                        <h4 className="h5 text-white">OUR MISSION</h4>
                        <p className="text-white">
                            We believe that fashion and art are symbiotic to human expression and hence we are the enablers of anyone who believes that this is their calling.
                        </p>
                        <h4 className="h5 text-white">OUR VISION</h4>
                        <p className="text-white">
                            We are Fashion Brand Builders, Who help Entrepreneurs to grow by addressing their executional difficulties and Tasks.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About