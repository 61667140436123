import React from 'react'

const Testimonials = () => {
    return (
        <section className="page-section pt-0 pb-0 mb-100 bg-dark-1" id="testimonials">
            <div className="container position-relative">
                <div className="pt-80 pb-80 pt-sm-70 pb-sm-70 px-4">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 wow fadeInUp">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2 className="homepage-section-heading section-title mb-70 mb-sm-40 text-white">Success Stories</h2>
                                </div>
                            </div>
                            <div className="testimonials-slider-1 pb-xs-80">
                                <div>
                                    <blockquote className="mb-0">
                                        <div className="blockquote-icon" aria-hidden="true">”</div>
                                        <p>
                                            Legacy Threads is the reason that we could move quick and launch our brand fast.
                                        </p>
                                        <div className="section-line mt-40"></div>
                                        <footer className="ts1-author mt-20 clearfix">
                                            <div className="ts1-author-img float-start">
                                                <img className="rounded-circle" width="44" height="44" src="images/ts1-user.jpg" alt="Image description is here" />
                                            </div>
                                            <div className="overflow-hidden text-white">
                                                Charu Vermani
                                                <div className="small">
                                                    Chaaravi - Co-founder
                                                </div>
                                            </div>
                                        </footer>
                                    </blockquote>
                                </div>
                                <div>
                                    <blockquote className="mb-0">
                                        <div className="blockquote-icon" aria-hidden="true">”</div>
                                        <p>
                                            They helped me Pivot and find the right product market mix and delivered top notch quality in everything.
                                        </p>
                                        <div className="section-line mt-40"></div>
                                        <footer className="ts1-author mt-20 clearfix">
                                            <div className="ts1-author-img float-start">
                                                <img className="rounded-circle" width="44" height="44" src="images/ts1-user.jpg" alt="Image description is here" />
                                            </div>
                                            <div className="overflow-hidden text-white">
                                                Preeti Wagh
                                                <div className="small">
                                                    Tilted Lotus, Founder
                                                </div>
                                            </div>
                                        </footer>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Testimonials