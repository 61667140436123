import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="page-section footer bg-dark-1 pb-30">
            <div className="container">
                <div className="row pb-120 pb-sm-80 pb-xs-50">
                    <div className="col-md-4 col-lg-3 text-gray mb-sm-50">
                        <div className="mb-30">
                            <img src="images/header-logo.png" width="105" height="34" alt="Your Company Logo" />
                        </div>
                        <p>
                            From Concept to Creation, we are the best manufacturing partner for your Apparel brand.
                        </p>
                        <div className="clearlinks">                                
                            <strong>T.</strong> <Link to="tel:+919811546161">+91-9811546161</Link>
                        </div>
                        <div className="clearlinks">
                            <strong>E.</strong> <Link to="mailto:inquiry@legacythreads.in">inquiry@legacythreads.in</Link>
                        </div>
                    </div>
                    <div className="col-md-7 offset-md-1 offset-lg-2">                            
                        <div className="row mt-n30">                        
                            <div className="col-sm-4 mt-30">
                                <h3 className="fw-title text-white">Company</h3>                            
                                <ul className="fw-menu clearlist">                                        
                                    <li><Link to="/about-us">About</Link></li>
                                    <li><Link to="/design-services">Design Services</Link></li>
                                    <li><Link to="/blogs">Blog</Link></li>
                                </ul>
                            </div>
                            <div className="col-sm-4 mt-30">
                                <h3 className="fw-title text-white">Social Media</h3>
                                <ul className="fw-menu clearlist">
                                    <li>
                                        <Link to="https://www.instagram.com/legacythreadsindia/?igsh=ZGNoMng4NnhueHAx" rel="noopener nofollow" target="_blank">
                                            <i className="fa-instagram"></i>
                                            Instagram
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.linkedin.com/showcase/legacy-threads/" rel="noopener nofollow" target="_blank">
                                            <i className="fa-linkedin"></i>
                                            LinkedIn
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-4 mt-30">
                                <h3 className="fw-title text-white">Legal & Press</h3>
                                <ul className="fw-menu clearlist">                                     
                                    <li><Link to="#">Privacy Policy</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul>
                            </div>
                        </div>                            
                    </div>
                </div>
                <div className="row text-gray">
                    <div className="col-md-4 col-lg-3">
                        <b>© LegacyThreads {(new Date().getFullYear())}.</b>
                    </div>
                    <div className="col-md-7 offset-md-1 offset-lg-2 clearfix">                    
                        <b>Based in India.</b>
                        <div className="local-scroll float-end mt-n20 mt-sm-10">
                            <Link to="#top" className="link-to-top">                                
                                <i className="mi-arrow-up size-24"></i>
                                <span className="visually-hidden">Scroll to top</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>                 
        </footer>
    )
}

export default Footer