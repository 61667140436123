import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { API_URL } from '../constants';
import axios from 'axios';

const SampleCsv = ()=>{

    const [totalPrice, setTotalPrice] = useState(0);
    const [error, setError] = useState(null);
    const [apiData, setApiData] = useState(null);
  
    useEffect(() => {
      const fetchApiData = async () => {
        try {
          const response = await fetch(API_URL+'getprice');
          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.error('Error fetching API data:', error);
        }
      };
  
      fetchApiData();
    }, []);
  
    const createLookupMap = (data, key) => {
      const map = {};
      data.forEach(item => {
        map[item.name] = item.price;
      });
      return map;
    };
  
    const segmentMap = apiData ? createLookupMap(apiData.segment, 'name') : {};
    const categoryMap = apiData ? createLookupMap(apiData.category, 'name') : {};
    const productTypeMap = apiData ? createLookupMap(apiData.productType, 'name') : {};
    const silhouetteTypeMap = apiData ? createLookupMap(apiData.silhouetteType, 'name') : {};
    const fabricMap = apiData ? createLookupMap(apiData.fabric, 'name') : {};
    const moqBucketMap = apiData ? createLookupMap(apiData.MOQBucket, 'name') : {};
  
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          calculateTotalPrice(results.data);
        },
        error: (error) => {
          setError(`Error parsing CSV: ${error.message}`);
        },
      });
    };
  
    const calculateTotalPrice = async (csvData) => {
      let total = 0;
  
      for (const row of csvData) {
        const segmentPrice = segmentMap[row.SEGMENT] || 0;
        const categoryPrice = categoryMap[row['CATEGORY TYPE']] || 0;
        const productTypePrice = productTypeMap[row['_1']] || 0;
        const silhouetteTypePrice = silhouetteTypeMap[row['_2']] || 0;
        const fabricPrice = fabricMap[row['_3']] || 0;
        const moqBucketPrice = moqBucketMap[row['_4']] || 0;
  
        total += segmentPrice + categoryPrice + productTypePrice + silhouetteTypePrice + fabricPrice + moqBucketPrice;
      }
  
      setTotalPrice(total);
    };
    console.log("Total Price>>>>>>>>>>",totalPrice)

    return(
        <center>
            <div className="mb-10 mb-md-10">
                <div className="row">
                    <div className="col-md-6 col-6"><h4 style={{float: "right"}}>Import CSV for Quote in bulk</h4></div>
                    <div className="col-md-6 col-6"><input type="file" id="importcsv" style={{float: "left"}} onChange={handleFileUpload} accept=".csv" /></div>
                </div>
                <h6>Get Quote in Bulk! &ensp; <a href="files/sample-csv.csv" className="btn btn-mod btn-border btn-small btn-round" data-btn-animate="y"><span className="btn-animate-y"><span className="btn-animate-y-1">Click Here</span><span className="btn-animate-y-2" aria-hidden="true">Click Here</span></span></a> to Download CSV Sample.</h6>
                <h6>OR</h6>
            </div>
        </center>
    )
}

export default SampleCsv
