import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const location = useLocation();

    const scrollToSection = (sectionId) => {
        if (location.pathname === '/') {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.location.href = `/#${sectionId}`;
        }
    };

    return (
        <nav className="main-nav transparent stick-fixed wow-menubar">
            <div className="main-nav-sub full-wrapper">
                <div className="nav-logo-wrap local-scroll">
                    <a href="/" className="logo">
                        <img src={process.env.PUBLIC_URL + '/images/header-logo.png'} alt="Your Company Logo" width="105" height="34" />
                    </a>
                </div>
                <div className="mobile-nav" role="button" tabIndex="0">
                    <i className="mobile-nav-icon"></i>
                    <span className="visually-hidden">Menu</span>
                </div>
                <div className="inner-nav desktop-nav">
                    <ul className="clearlist scroll-nav local-scroll">
                        <li><a href="/" className="active">Home</a></li>
                        <li><a onClick={() => scrollToSection('services')}>Services</a></li>
                        <li><a onClick={() => scrollToSection('about-us')}>About</a></li>
                        <li><a onClick={() => scrollToSection('testimonials')}>Testimonials</a></li>
                        <li><a href="/design-services">Design Services</a></li>
                    </ul>
                    <ul className="items-end clearlist local-scroll">
                        <li>
                            <a href="/login" className="opacity-1 no-hover">
                                <span className="link-hover-anim underline" data-link-animate="y">Sign In</span>
                            </a>
                        </li>
                        <li>
                            <a href="/get-a-quote" className="opacity-1 no-hover">
                                <span className="link-hover-anim underline" data-link-animate="y">Get a quote</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
