import React from 'react'
import { Link } from "react-router-dom";

const GuidelinesEthnicKids = () => {
    return (
        <div>
            <section className="page-section bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/images/full-width-images/section-bg-1.jpg'})` }} id="home">
                <div className="container position-relative pt-30 pt-sm-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">Guidelines To Ethnic For Kids</span>
                                </h1>
                                <div className="blog-item-data mt-30 mt-sm-10 mb-0 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-clock size-16"></i><span className="visually-hidden">Date:</span> August 17</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-user size-16"></i><span className="visually-hidden">Author:</span> Legacy Threads</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <i className="mi-folder size-16"></i>
                                        <span className="visually-hidden">Categories:</span>
                                        <Link to="#">Ethnic</Link>, <Link to="#">Kids</Link>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>
            </section>
            <section className="page-section">
                <div className="container relative">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="blog-item mb-80 mb-xs-40">
                                <div className="blog-item-body">                                       
                                    <p>"Ethnic kidswear includes a variety of traditional and culturally inspired clothing designed for children. Here are broad categories in ethnic kidswear:</p>
                                    <h6>Kurta Pajama/ Co-Ord Set</h6>
                                    <ul>
                                        <li><b>Kurta:</b> A long, loose-fitting shirt.</li>
                                        <li><b>Pajama:</b> Loose-fitting pants, often with an elastic waist.</li>
                                        <li>Comfortable and versatile, suitable for both boys and girls.</li>
                                    </ul>
                                    <h6>Lehenga Choli / Ghagra Choli</h6>
                                    <ul>
                                        <li><b>Lehenga:</b> A flared skirt.</li>
                                        <li><b>Choli:</b> A fitted blouse.</li>
                                        <li>Often paired with a <b>dupatta</b> (scarf) for girls.</li>
                                        <li>Worn for weddings, festivals, and special occasions.</li>
                                    </ul>
                                    <h6>Sherwani</h6>
                                    <ul>
                                        <li>A long coat-like garment worn over a kurta.</li>
                                        <li>Often adorned with embroidery and embellishments.</li>
                                        <li>Paired with churidars or pajamas.</li>
                                        <li>Commonly worn by boys for formal occasions.</li>
                                    </ul>
                                    <h6>Frock</h6>
                                    <ul>
                                        <li>Traditional Indian frocks are often elaborately decorated.</li>
                                        <li>Suitable for girls for casual and festive occasions.</li>
                                        <li>Can be knee-length or ankle-length.</li>
                                    </ul>
                                    <h6>Waistcoat and Jacket</h6>
                                    <ul>
                                        <li>Sleeveless waistcoats and jackets can be worn over kurtas or shirts.</li>
                                        <li>Adds a formal touch to ethnic outfits.</li>
                                        <li>Suitable for both boys and girls.</li>
                                    </ul>
                                    <h6>Ethnic Rompers and Jumpsuits</h6>
                                    <ul>
                                        <li>One-piece garments inspired by traditional designs.</li>
                                        <li>Often feature traditional prints, embroidery, and embellishments.</li>
                                        <li>Convenient and comfortable for infants and toddlers.</li>
                                    </ul>
                                    <cite>These categories encompass a wide range of traditional and contemporary ethnic kidswear, each with its unique style, significance, and regional variation."</cite>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default GuidelinesEthnicKids