import React from 'react'
import { Link } from "react-router-dom";

const Achievement = () => {
    return (
        <section className="page-section bg-dark-1 bg-dark-alpha-90 parallax-5 light-content" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/full-width-images/section-bg-2.jpg'})` }}>
            <div className="container position-relative">
                <div className="row">
                    <div className="col-lg-4 mb-md-60 mb-xs-50">
                        <h2 className="section-title mb-20 wow fadeInUp">Numbers of Note</h2>
                    </div>
                    <div className="col-lg-7 offset-lg-1">
                        <div className="row mt-n50 mt-xs-n30">
                            <div className="col-sm-6 col-lg-5 mt-50 mt-xs-30 wow fadeScaleIn" data-wow-delay="0.4s">
                                <div className="number-title mb-10">
                                    50+
                                </div>
                                <div className="number-descr">
                                    Brands across various projects
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-5 offset-lg-2 mt-50 mt-xs-30 wow fadeScaleIn" data-wow-delay="0.6s">
                                <div className="number-title mb-10">
                                    1500+
                                </div>
                                <div className="number-descr">
                                    Designs from concept to prototype
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-5 mt-50 mt-xs-30 wow fadeScaleIn" data-wow-delay="0.8s">
                                <div className="number-title mb-10">
                                    2 Lakh+
                                </div>
                                <div className="number-descr">
                                    Units of inventory
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-5 offset-lg-2 mt-50 mt-xs-30 wow fadeScaleIn" data-wow-delay="1s">
                                <div className="number-title mb-10">
                                    12+
                                </div>
                                <div className="number-descr">
                                    Years of industry experience
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Achievement