import React from "react";
import { Link } from "react-router-dom";

const AboutAndCatalog = () => {
    return (
        <section className="page-section pt-0 pb-0" id="about-us">
            <div className="container position-relative">
                <div className="row mb-60 mb-xs-30">
                    <div className="col-md-12">
                        <h2 className="homepage-section-heading section-caption mb-xs-10">About Us</h2>
                        <h3 className="section-title mb-0"><span className="wow charsAnimIn" data-splitting="chars">Premium Quality Manufacturing</span></h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7 d-flex mb-md-60 mb-xs-30">
                        <div className="call-action-1-images">
                            <div className="call-action-2-image-2 about-us-home-section-img">
                                <img src="images/promo-8.png" alt="Image Description" className="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="134" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 d-flex">
                        <div className="col-md-12 col-12 wow fadeInUp"  data-wow-duration="1.2s" data-wow-offset="255">
                        <p className="about-bottom-text">Legacy Threads was born from a vision—a vision of excellence, quality, and a relentless passion for craftsmanship. Founded by Rakesh Sareen Legacy Threads began its journey as the exclusive manufacturing unit for the brand Abhishti.<br/>
                        From the start, the team worked closely with the design and concepts , ensuring every piece was crafted to perfection.
                        However, as Abhishti grew and welcomed new investors, the need for larger inventory pushed production to</p>
                            <div className="call-action-2-text mb-50 mb-sm-40">
                                <div className="local-scroll">
                                    <Link to="/about-us" className="btn btn-mod btn-large btn-round btn-hover-anim"><span>Know More</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutAndCatalog